import {isWebviewApp} from "./config.utils";
import {webviewAppIds} from "./webview";

export function getSubscriptionSku() {
  const subscriptionSkus = {
    toonmeAndroid: "week_2",
    toonmeIOS: "pro_features_tnmweek_4",
    photolabAndroid: "week_2",
    photolabIOS: "pro_subscr_weekly_4",
  };

  if (isWebviewApp(webviewAppIds.toonme)) {
    if (window.clientConfig.isWebviewAndroid) {
      return subscriptionSkus.toonmeAndroid;
    } else {
      return subscriptionSkus.toonmeIOS;
    }
  } else {
    if (window.clientConfig.isWebviewAndroid) {
      return subscriptionSkus.photolabAndroid;
    } else {
      return subscriptionSkus.photolabIOS;
    }
  }
}

export function getInAppSku() {
  const inAppSkus = {
    toonme: "tm_unite_1",
    photolab: "pl_unite_1",
  };

  if (isWebviewApp(webviewAppIds.toonme)) {
    return inAppSkus.toonme;
  } else {
    return inAppSkus.photolab;
  }
}